import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  GuardResult,
  RedirectCommand,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { from, Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { LoginConstants } from "../models/constants/loginConstants";
import { AuthZTypes } from "../models/login/AuthZTypes";
import { serviceFactory } from "../services/serviceLayer/servicefactory/serviceFactory";
import { ToasterService } from "../services/toaster.service";

const REDIRECT_LOCALSTORAGE_TOKEN = "redirect_after_login";

/**
 * Check whether the user is authenticated using SiemensID and authorized using AuthZ.
 * Redirects if not authenticated or authorized.
 */
@Injectable({
  providedIn: "root",
})
export class IsAuthorized implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService,
    private toasterService: ToasterService
  ) {}

  errorRedirectUrl = this.router.parseUrl("/notregistered");
  inActiveUserRedirect = this.router.parseUrl("/inactiveUser");
  loginRedirectUrl = this.router.parseUrl("/login");
  registerUrl = this.router.parseUrl("/register");

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<GuardResult> {
    return this.auth.user$.pipe(
      mergeMap((user) => {
        /**
         * User is authenticated with SiemensID.
         * Checks if the user is authorized with AuthZ
         */
        if (user) {
          return from(
            serviceFactory.AuthService.checkUserRegistration(
              user[LoginConstants.GID]
            )
          ).pipe(
            map((registrationStatus) =>
              this.checkUserRegStatus(registrationStatus, state)
            ),
            catchError((error) => {
              return of(
                new RedirectCommand(this.errorRedirectUrl, {
                  skipLocationChange: true,
                })
              );
            })
          );
        } else {
          window.localStorage.setItem(REDIRECT_LOCALSTORAGE_TOKEN, state.url);
          return of(new RedirectCommand(this.loginRedirectUrl));
        }
      })
    );
  }

  checkUserRegStatus(
    status: AuthZTypes,
    state: RouterStateSnapshot
  ): GuardResult {
    this.loginRedirectUrl.queryParams = {
      returnUrl: state.url,
      isNotAuthenticated: true,
    };
    switch (status) {
      case AuthZTypes.ALREADY_REGISTERED: {
        /**
         * We want users who attempt to access a project page or a curve view directly
         * to be redirected to this URL once they log in.
         * Typically, this feature can be handled by Auth0 through the redirect_uri parameters.
         * However, since we currently cannot make any changes within Auth0, we store the attempted
         * URI in local storage, retrieve it after a successful login, and then remove it.
         */

        const redirectUrl = window.localStorage.getItem(
          REDIRECT_LOCALSTORAGE_TOKEN
        );

        if (redirectUrl) {
          window.localStorage.removeItem(REDIRECT_LOCALSTORAGE_TOKEN);
          return new RedirectCommand(this.router.parseUrl(redirectUrl));
        } else return true;
      }
      case AuthZTypes.ACCOUNT_ACTIVATED: {
        this.toasterService.showInfo(
          this.toasterService.toasterMessage(
            "toaster-message.activationSuccessful"
          )
        );
        /**
         * We want users who attempt to access a project page or a curve view directly
         * to be redirected to this URL once they log in.
         * Typically, this feature can be handled by Auth0 through the redirect_uri parameters.
         * However, since we currently cannot make any changes within Auth0, we store the attempted
         * URI in local storage, retrieve it after a successful login, and then remove it.
         */

        const redirectUrl = window.localStorage.getItem(
          REDIRECT_LOCALSTORAGE_TOKEN
        );

        if (redirectUrl) {
          window.localStorage.removeItem(REDIRECT_LOCALSTORAGE_TOKEN);
          return new RedirectCommand(this.router.parseUrl(redirectUrl));
        } else return true;
      }
      case AuthZTypes.NOT_REGISTERED: {
        return new RedirectCommand(this.registerUrl, {
          skipLocationChange: false,
        });
      }
      case AuthZTypes.INACTIVE_USER: {
        return new RedirectCommand(this.inActiveUserRedirect, {
          skipLocationChange: true,
        });
      }
    }
  }
}

/**
 * Check whether the user is authenticated using SiemensID.
 * Redirects if not authenticated.
 */
@Injectable({
  providedIn: "root",
})
export class IsAuthenticatedGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<GuardResult> {
    return this.auth.isAuthenticated$.pipe(
      map((isAuthenticated) => {
        if (isAuthenticated) return true;
        else
          return new RedirectCommand(this.router.parseUrl("/login"), {
            skipLocationChange: true,
          });
      })
    );
  }
}
