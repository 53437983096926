import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi
} from "@angular/common/http";
import {
  ApplicationConfig,
  APP_INITIALIZER,
  importProvidersFrom
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideRouter } from "@angular/router";
import { AuthModule, AuthService, AuthState } from "@auth0/auth0-angular";
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule
} from "@ngx-translate/core";
import axios from "axios";
import { NgEventBus } from "ng-event-bus";
import { ToastrModule } from "ngx-toastr";
import {
  catchError,
  filter,
  first,
  mapTo,
  mergeMap,
  Observable,
  of,
  throwError
} from "rxjs";
import { CustomLoader } from "src/app/language/custom.loader";
import { LanguageMissingTranslationHandler } from "src/app/language/language.missing-translation-handler";
import { environment } from "src/environments/environment";
import { routes } from "./app.routes";

function initializeAxios(
  authService: AuthService,
  authState: AuthState
): () => Observable<any> {
  // ugly code adapted from auth0-angular AuthHttpInterceptor to forward bearer token
  const waitUntil = (signal$) => (source$) =>
    source$.pipe(mergeMap((value) => signal$.pipe(first(), mapTo(value))));
  return () => {
    axios.interceptors.request.use(
      (config) => {
        const isLoaded$ = authService.isLoading$.pipe(
          filter((isLoading) => !isLoading)
        );
        return of(config)
          .pipe(waitUntil(isLoaded$))
          .toPromise()
          .then((_) =>
            authService
              .getAccessTokenSilently()
              .pipe(
                catchError((err) => {
                  authState.setError(err);
                  return throwError(err);
                })
              )
              .toPromise()
          )
          .then((token) => {
            config.headers["Authorization"] = `Bearer ${token}`;
            return config;
          });
      },
      (error) => {
        console.log("error in axios response");
        return Promise.reject(error);
      }
    );
    return null;
  };
}
export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    importProvidersFrom(
      BrowserModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useClass: CustomLoader,
          deps: [HttpClient],
        },
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: LanguageMissingTranslationHandler,
        },
      }),
      ToastrModule.forRoot({
        timeOut: 5000,
        positionClass: "toast-top-right",
        preventDuplicates: true,
        closeButton: true,
      }),
      AuthModule.forRoot(
        // XXX cast to any required as a workaround since 'authorizationParams.display' is typed according to auth0 api but this parameter
        // is used a string by Siemens Universal Login Page (see https://wiki.siemens.com/display/en/Universal+Login+Page)
        {
          ...environment.auth,
        } as any
      )
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAxios,
      deps: [AuthService, AuthState],
      multi: true,
    },
    {
      provide: Window,
      useValue: window,
    },
    NgEventBus,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
};
